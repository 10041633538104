import React from 'react'
import RecipeImg from '../Assets/recipe.jpeg'
import ListImg from '../Assets/list.jpeg'
import PhotoGalleryImg from '../Assets/gallery.jpeg'
import CountriesImg from '../Assets/countries.jpg'
import IiberstImg from '../Assets/estste.jpeg'
import BrewsterhubImg from '../Assets/kff.jpeg'
import { useMediaQuery } from 'react-responsive'

function MyProjects() {
  const isDesktop = useMediaQuery({minWidth:994})
  const isMobile = useMediaQuery({maxWidth:1224})
  return (
    <>
      {isDesktop && (
        <div className='mt-12 text-white'>
          <h1 className='ml-52 uppercase font-semibold text-2xl'>SELECTED WORK</h1>
          <div className='grid grid-cols-3 p-12 mx-48 opacity-50'>
              <a style={{backgroundImage:`url(${RecipeImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'28rem', width:'20rem'}} href="https://dierecipe.netlify.app" className='grid place-content-center uppercase font-semibold'>
                Recipe App</a>
              <a style={{backgroundImage:`url(${ListImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'28rem', width:'20rem'}} href="https://zakiya-todo-list.netlify.app" className='grid place-content-center uppercase font-semibold'>Todolist App</a>
              <a style={{backgroundImage:`url(${PhotoGalleryImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'28rem', width:'20rem'}} href="https://bintadam.github.io/photo-gallery/" className='grid place-content-center uppercase font-semibold'>Photo Gallery App</a>
              <a style={{backgroundImage:`url(${CountriesImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'28rem', width:'20rem'}} href="https://bintadam.github.io/Countries/" className='grid place-content-center uppercase font-semibold'>Countries App</a>
              <a style={{backgroundImage:`url(${IiberstImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'28rem', width:'20rem'}} href="https://iiberst.netlify.app" className='grid place-content-center uppercase font-semibold'>IIBERST</a>
              <a style={{backgroundImage:`url(${BrewsterhubImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'28rem', width:'20rem'}} href="https://brewsterhub.netlify.app" className='grid place-content-center uppercase font-semibold'>brewsterhub</a>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mt-12 text-white'>
          <h1 className='uppercase font-semibold text-xl ml-8'>SELECTED WORK</h1>
          <div className='grid grid-cols-2 opacity-50 mx-2'>
              <a style={{backgroundImage:`url(${RecipeImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'20rem', width:'14rem', marginTop:'8px'}} href="https://dierecipe.netlify.app" className='grid place-content-center uppercase font-semibold'>
                Recipe App</a>
              <a style={{backgroundImage:`url(${ListImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'20rem', width:'14rem', marginTop:'8px'}} href="https://zakiya-todo-list.netlify.app" className='grid place-content-center uppercase font-semibold'>Todolist App</a>
              <a style={{backgroundImage:`url(${PhotoGalleryImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'20rem', width:'14rem', marginTop:'8px'}} href="https://bintadam.github.io/photo-gallery/" className='grid place-content-center uppercase font-semibold'>Photo Gallery App</a>
              <a style={{backgroundImage:`url(${CountriesImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'20rem', width:'14rem', marginTop:'8px'}} href="https://bintadam.github.io/Countries/" className='grid place-content-center uppercase font-semibold'>Countries App</a>
              <a style={{backgroundImage:`url(${IiberstImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'20rem', width:'14rem', marginTop:'8px'}} href="https://iiberst.netlify.app" className='grid place-content-center uppercase font-semibold'>IIBERST</a>
              <a style={{backgroundImage:`url(${BrewsterhubImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'20rem', width:'14rem', marginTop:'8px'}} href="https://brewsterhub.netlify.app" className='grid place-content-center uppercase font-semibold'>brewsterhub</a>
          </div>
        </div>
      )}
    </>
  )
}

export default MyProjects
