import React, { useEffect } from 'react'
import ResumePDF from '../Resume/White Minimalist Resume.pdf'

function Resume() {
  useEffect(() => {
    const link = document.createElement('a')
    link.href = ResumePDF
    link.download = 'My_Resume.pdf' // The file name for download
    document.body.appendChild(link) // Append to the body
    link.click() // Simulate click
    document.body.removeChild(link) // Remove the link when done
  }, []) // Empty dependency array means this effect will only run once after the initial render
  return (
    <>
      <iframe src={ResumePDF} width="100%" height="500px" title="Resume">
        <a href={ResumePDF} download>here</a>
      </iframe>
    </>
  )
}

export default Resume
