import React from 'react'
import { useMediaQuery } from 'react-responsive'

function Contact() {
  const isDesktop = useMediaQuery({minWidth:994})
  const isMobile = useMediaQuery({maxWidth:1224})
  return (
    <>
      {isDesktop && (
        <div className='text-white uppercase'>
            <h1 className='flex justify-center text-center text-2xl font-semibold mt-8'>contact me</h1>
            <form className='flex flex-col items-center mx-96 mt-6 border border-white rounded py-6 opacity-50'>
                <input type='text' placeholder='NAME' className='border border-white bg-black rounded focus:outline-none py-3 pl-1 my-3 w-2/3'/>
                <input type='email' placeholder='EMAIL' className='border border-white bg-black rounded focus:outline-none py-3 pl-1 my-3 w-2/3'/>
                <textarea placeholder='meessage' className='border border-white bg-black rounded focus:outline-none pt-2 pb-32 pl-1 w-2/3' />
                <button className='bg-white text-black px-8 py-3 rounded font-semibold my-4'>SEND</button>
            </form>
        </div>
      )}
      {isMobile && (
        <div className='mx-8 text-white uppercase '>
            <h1 className='flex justify-center text-center text-xl font-semibold mt-16'>contact me</h1>
            <form className='mt-3 opacity-50'>
                <input type='text' placeholder='NAME' className='border border-white bg-black rounded focus:outline-none py-3 pl-1 my-3 w-full'/>
                <input type='email' placeholder='EMAIL' className='border border-white bg-black rounded focus:outline-none py-3 pl-1 my-3 w-full'/>
                <textarea placeholder='meessage' className='border border-white bg-black rounded focus:outline-none pt-2 pb-32 pl-1 w-full' />
                <button className='bg-white text-black px-16 py-3 rounded font-semibold mt-3 mb-8'>SEND</button>
            </form>
        </div>
      )}
    </>
  )
}

export default Contact