import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import MyTools from './MyTools'
import MyProjects from './MyProjects'
import Contact from './Contact'
import HImg from '../Assets/h.jpeg'
import Slider from 'react-slick';

function Home() {
  const isDesktop = useMediaQuery({minWidth:994})
  const isMobile = useMediaQuery({maxWidth:1224})
  const [colorInd, setColorInd] = useState(0)
  const colors = [
    'text-rose-500',
    'text-sky-500',
    'text-blue-500',
    'text-indigo-500',
    'text-purple-500',
    'text-fuchsia-500',
    'text-pink-500'

  ]
  useEffect(() =>{
    const intervalId = setInterval(() =>{
      setColorInd((prevIndex) => (prevIndex + 1) % colors.length)
    }, 2000)
    return () => clearInterval(intervalId)
  }, [colors.length])

  const settings = {
    slidesToShow:1,
    slidesToScroll: 1,
    infinite:true,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows:false
}

  return (
    <>
      {isDesktop && (
        <div>
          <div className='grid grid-cols-2 mx-40 text-white mb-20'>
            <div className='mt-40'>
              <h1 className='mb-4'>Hello, <span className='block text-6xl  mt-3'>I'm <strong className={`${colors[colorInd]} font-bold`}>Zakiya Adam</strong></span></h1>
              <h3 className='text-xl semi-bold'>Explorer of Digital Landscapes</h3>
              <p className='text-base font-thin tracking-wide mr-8 mt-3'><span className=''>Crafting Pixels &Code into Experiences</span>Armed with a palette of technology and a dash of artistry, I navigate the ever-evolving terrain of UI/UX design and software development.</p>
            </div>
            <img src={HImg} alt="" className='mt-4 opacity-50 bg-black' />
          </div>
          <div className='mx-60 mt-20'>
            <div className='grid grid-cols-3 text-white font-thin gap-5 opacity-75'>
              <div className='border border-white rounded p-6 shadow-lg transform transition duration-500 hover:scale-105'>
                <h2 className='font-playfair mb-2 font-bold text-lg'><span className='text-sm mr-2'>01</span> WEB DESIGN</h2>
                <p>I am dedicated to creating elegant and effective websites, emphasizing user-friendly design and responsive functionality</p>
              </div>
              <div className='border border-white rounded p-6 shadow-lg transform transition duration-500 hover:scale-105'>
                <h2 className='font-playfair mb-2 font-bold text-lg'><span className='text-sm mr-2'>02</span> DEVELOPMENT</h2>
                <p>I develop high-performance websites optimized for functionality and scalability, utilizing the latest in front-end technology</p>
              </div>
              <div className='border border-white rounded p-6 shadow-lg transform transition duration-500 hover:scale-105'>
                <h2 className='font-playfair mb-2 font-bold text-lg'><span className='text-sm mr-2'>03</span> UX/UI</h2>
                <p>I design intuitive and engaging user interfaces, focusing on optimizing user experience through effective design principles.</p>
              </div>
            </div>
          </div>
          <MyTools/>
          <MyProjects/>
          <Contact/>
        </div>
      )}
      {isMobile && (
        <div>
          <div className='mt-8 text-white mb-16'>
            <div className='mx-10'>
              <h1 className='mb-1'>Hello, <span className='block   '>I'm <strong className={`${colors[colorInd]} font-bold ml-2 text-2xl`}>Zakiya Adam</strong></span></h1>
              <h3 className='text-base semi-bold'>Explorer of Digital Landscapes</h3>
              <p className='text-base font-thin tracking-wide mr-8 mt-1'><span className=''>Crafting Pixels &Code into Experiences</span>Armed with a palette of technology and a dash of artistry, I navigate the ever-evolving terrain of UI/UX design and software development.</p>
            </div>
          </div>
          <Slider {...settings} className='mx-16 mt-20 text-white font-thin opacity-75'>
              <div className='border border-white rounded p-6 shadow-lg transform transition duration-500 hover:scale-105'>
                <h2 className='font-playfair mb-2 font-bold text-lg'><span className='text-sm mr-2'>01</span> WEB DESIGN</h2>
                <p>I am dedicated to creating elegant and effective websites, emphasizing user-friendly design and responsive functionality</p>
              </div>
              <div className='border border-white rounded p-6 shadow-lg transform transition duration-500 hover:scale-105'>
                <h2 className='font-playfair mb-2 font-bold text-lg'><span className='text-sm mr-2'>02</span> DEVELOPMENT</h2>
                <p>I develop high-performance websites optimized for functionality and scalability, utilizing the latest in front-end technology</p>
              </div>
              <div className='border border-white rounded p-6 shadow-lg transform transition duration-500 hover:scale-105'>
                <h2 className='font-playfair mb-2 font-bold text-lg'><span className='text-sm mr-2'>03</span> UX/UI</h2>
                <p>I design intuitive and engaging user interfaces, focusing on optimizing user experience through effective design principles.</p>
              </div>
          </Slider>
          <MyTools/>
          <MyProjects/>
          <Contact/>
        </div>
      )}
    </>
  )
}

export default Home