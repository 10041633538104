import React from 'react'

function RangeInputSlider({value, max =100, min=0, fillColor = '#4caf50', backgroundColor = '#808080'}){
    const fillPercentage = ((value - min) / (max - min)) * 100;
    const backgroundStyle = {
        background: `linear-gradient(to right, ${fillColor} 0%, ${fillColor} ${fillPercentage}%, ${backgroundColor} ${fillPercentage}%)`,
        width: '80%',
        height: '2px',
        borderRadius: '4px',
        cursor: 'pointer',
        appearance: 'none',
        outline: 'none',
        marginTop: '12px'
    };
    return (
        <input type="range" className="range-slider appearance-none w-full h-2 bg-gray-300 rounded-md outline-none cursor-pointer" value={value} min={min}
        max={max}  style={backgroundStyle}/>
    )
}

export default RangeInputSlider