import React from 'react'
import RangeInputSlider from './RangeInputSlider'
import HtmlImg from '../Assets/html.png'
import NodeImg from '../Assets/node.png'
import ReactImg from '../Assets/React.png'
import MongodbImg from '../Assets/mongodb.png'
import CssImg from '../Assets/css3.png'
import BootstrapImg from '../Assets/bootstrap.png'
import PythonImg from '../Assets/Python.png'
import JavascriptImg from '../Assets/javascript.png'
import DjangoImg from '../Assets/django.jpeg'
import PostgresImg from '../Assets/Postgresql.png'
import GitImg from '../Assets/git.png'
import FastApiImg from '../Assets/fastapi.png'
import { useMediaQuery } from 'react-responsive'

function MyTools() {
  const isDesktop = useMediaQuery({minWidth:994})
  const isMobile = useMediaQuery({maxWidth:1224})
  return (
    <>
    {isDesktop && (
        <div className='mt-28 mx-16 p-12'>
            <h1 className='font-semibold text-2xl ml-20 uppercase text-white'>My skills</h1>
            <div className='grid grid-cols-2 py-4 px-12 mx-8 gap-10'>
                <div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={60} min={0} max={100} fillColor="#29b440" backgroundColor="#cccccc" />
                    <img src={NodeImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={90} min={0} max={100} fillColor="#38b3e3" backgroundColor="#cccccc" />
                    <img src={ReactImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={90} min={0} max={100} fillColor="#ffd55c" backgroundColor="#cccccc" />
                    <img src={JavascriptImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={100} min={0} max={100} fillColor="#ff6100" backgroundColor="#cccccc" />
                    <img src={HtmlImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={90} min={0} max={100} fillColor="#38b3e3" backgroundColor="#cccccc" />
                    <img src={CssImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={70} min={0} max={100} fillColor="#29b440" backgroundColor="#cccccc" />
                    <img src={MongodbImg} className='h-8 ml-6' alt="" />
                  </div>
                </div>
                <div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={75} min={0} max={100} fillColor="#6a006a" backgroundColor="#cccccc" />
                    <img src={BootstrapImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={85} min={0} max={100} fillColor="#3b6687" backgroundColor="#cccccc" />
                    <img src={PythonImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={80} min={0} max={100} fillColor="#004b3a" backgroundColor="#cccccc" className=''/>
                    <img src={DjangoImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={75} min={0} max={100} fillColor="#1b788f" backgroundColor="#cccccc" />
                    <img src={PostgresImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={40} min={0} max={100} fillColor="#16b48b" backgroundColor="#cccccc" />
                    <img src={GitImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={70} min={0} max={100} fillColor="#FF0000" backgroundColor="#cccccc" />
                    <img src={FastApiImg} className='h-8 ml-6' alt="" />
                  </div>
                </div>
            </div>
        </div>
      )}
      {isMobile && (
        <div className='mt-16'>
            <h1 className='font-semibold text-2xl ml-12 uppercase text-white'>My skills</h1>
            <div className='py-4 px-12'>
                <div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={60} min={0} max={100} fillColor="#29b440" backgroundColor="#cccccc" />
                    <img src={NodeImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={90} min={0} max={100} fillColor="#38b3e3" backgroundColor="#cccccc" />
                    <img src={ReactImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={90} min={0} max={100} fillColor="#ffd55c" backgroundColor="#cccccc" />
                    <img src={JavascriptImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={100} min={0} max={100} fillColor="#ff6100" backgroundColor="#cccccc" />
                    <img src={HtmlImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={90} min={0} max={100} fillColor="#38b3e3" backgroundColor="#cccccc" />
                    <img src={CssImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={70} min={0} max={100} fillColor="#29b440" backgroundColor="#cccccc" />
                    <img src={MongodbImg} className='h-8 ml-6' alt="" />
                  </div>
                </div>
                <div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={75} min={0} max={100} fillColor="#6a006a" backgroundColor="#cccccc" />
                    <img src={BootstrapImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                    <RangeInputSlider value={85} min={0} max={100} fillColor="#3b6687" backgroundColor="#cccccc" />
                    <img src={PythonImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={80} min={0} max={100} fillColor="#004b3a" backgroundColor="#cccccc" className=''/>
                    <img src={DjangoImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={75} min={0} max={100} fillColor="#1b788f" backgroundColor="#cccccc" />
                    <img src={PostgresImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={40} min={0} max={100} fillColor="#16b48b" backgroundColor="#cccccc" />
                    <img src={GitImg} className='h-8 ml-6' alt="" />
                  </div>
                  <div className='flex justify-between my-4'>
                  <RangeInputSlider value={70} min={0} max={100} fillColor="#FF0000" backgroundColor="#cccccc" />
                    <img src={FastApiImg} className='h-8 ml-6' alt="" />
                  </div>
                </div>
            </div>
        </div>
      )}
    </>
  )
}

export default MyTools