import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Resume from './Components/Resume'

function App() {
  return (
    <div className="bg-black">
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/resume' element={<Resume/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
