import React from 'react'
import {Link} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

function Navbar() {
  const isDesktop = useMediaQuery({minWidth:994})
  const isMobile = useMediaQuery({maxWidth:1224})
  return (
    <>
    {isDesktop && (
        <nav className='flex justify-end text-end text-white uppercase text-lg pt-4 mx-32 pt-8'>
          <ul>
            <Link to='/resume'>
              <li>Resume</li>
            </Link>
          </ul>
        </nav>
      )}
      {isMobile && (
        <nav className='flex justify-end text-end text-white uppercase text-lg pt-4 mr-12 pt-8'>
          <ul>
            <Link to='/resume'>
              <li>Resume</li>
            </Link>
          </ul>
        </nav>
      )}
    </>
  )
}

export default Navbar